import React from 'react'



const ProductCard = ({ index, name, gender, volume, price, image }) => {


  const genderText = gender === 'men' 
    ? 'Kişi' 
    : gender === 'women' 
    ? 'Qadın' 
    : gender === 'unisex' 
    ? 'Unisex' 
    : '';


  return (
<div className="bg-[#3F3F3F] rounded-[20px] min-h-[199px] md:w-[271px] md:min-h-[280px] pt-[1px] mx-auto md:mx-0 flex flex-col justify-between" key={index}>
  <div className="m-3 text-[white] flex flex-col justify-between h-full">
    <div className="bg-[white] rounded-xl h-[99px] md:h-[167px] mb-3 flex justify-center items-center">
      <img className="w-[103px] h-[79px] md:w-[147px] mx-auto" src={`https://www.kcollection.az/kcollection/${image}`} alt="" />
    </div>
    <div className="flex flex-col">
      <h3 className="text-left text-[14px] md:text-[18px] font-medium min-h-[40px] md:min-h-[48px]">{name}</h3>
      <h3 className="text-left text-[14px] md:text-base text-[#E9E9E9]">{genderText} • <span>{volume} ml</span></h3>
      <h3 className="text-[#dfb66f] text-left text-[16px] md:text-[20px] font-medium md:font-semibold">{price} AZN</h3>
    </div>
  </div>
</div>

  )
}

export default ProductCard