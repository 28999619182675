import React from 'react';
import { hero1, hero2, poster, poster1, poster2 } from '../../../assets/images';

const Poster = () => {
  return (
  <div className='bg-[#DFB66F]'>
      <div className="md:max-w-container max-w-containerSm mx-auto md:flex justify-between py-10 text-[white] ">
    <div className='flex justify-center md:block'><img className='md:h-[56px] h-[109px]' src={poster1}></img></div>
    <div className='md:ml-[42px] mt-[20px] md:mb-0 mb-[20px] md:w-[842px]'>
      <p className='text-[24px] md:text-[40px] font-bold text-[black] text-center md:text-left'>Hər Ətirimizlə Zərifliyi Yenidən kəşf edin.</p>
      <p className='mt-4 md:text-[26px] text-[16px] font-light md:font-normal mb-[10px] text-[#3F3F3F] text-center md:text-left'>“Sizi fərqləndirən incə və yaddaqalan qoxular hər anınıza özəl bir toxunuş qatır”</p>
      </div>
    <div className='flex justify-center md:flex-col md:justify-end'><img className='md:h-[56px] h-[104px]' src={poster2}></img></div>
</div>
  </div>
  );
};

export default Poster;

