import React, { useEffect, useState } from "react";
import PaginatedItems from './PaginatedItems';
import axios from "axios";
import { useLocation } from "react-router-dom";
import { close, filterle, logo } from "../../assets/images";

const Products = () => {
  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOption, setSortOption] = useState("default");
  const [brandFilters, setBrandFilters] = useState({});
  const [genderFilters, setGenderFilters] = useState({});
  const [priceFilter, setPriceFilter] = useState("default");
  const [brandSearch, setBrandSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true)


  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
  const toggleHamburgerMenu = () => setIsHamburgerMenuOpen(!isHamburgerMenuOpen);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await axios.get(
        `https://www.kcollection.az/kcollection/dashboard.php`,
        { withCredentials: false }
      );
  

      setProducts(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleBrandChange = (brand) => {
    setBrandFilters((prev) => ({
      ...prev,
      [brand]: !prev[brand],
    }));
  };

  const handleGenderChange = (gender) => {
    setGenderFilters((prev) => ({
      ...prev,
      [gender]: !prev[gender],
    }));
  };

  const handlePriceChange = (price) => {
    setPriceFilter(price);
  };

  const clearBrandFilters = () => {
    setBrandFilters({});
    setBrandSearch("");
  };

  // Filtering logic
  const filteredProducts = products.filter(product => {
    const matchesSearch = product.name.toLowerCase().includes(searchQuery.toLowerCase());

    // Check if brand filters are applied
    const hasSelectedBrands = Object.keys(brandFilters).some(key => brandFilters[key]);
    const matchesBrand = !hasSelectedBrands || brandFilters[product.brand];

    // Check if gender filters are applied
    const hasSelectedGenders = Object.keys(genderFilters).some(key => genderFilters[key]);
    const matchesGender = !hasSelectedGenders || genderFilters[product.gender];

    return matchesSearch && matchesBrand && matchesGender;
  });

  // Sort products based on the selected option
  const sortedProducts = [...filteredProducts].sort((a, b) => {
    if (sortOption === "lowToHigh") {
      return a.price - b.price; // Sort ascending by price
    } else if (sortOption === "highToLow") {
      return b.price - a.price; // Sort descending by price
    }
    return 0; // No sorting
  });

  // Apply price filter
  const finalProducts = priceFilter === "default" ? sortedProducts : sortedProducts.filter(product => {
    if (priceFilter === "low") return product.price > 100 && product.price < 500; // Example: products priced below 50
    if (priceFilter === "high") return product.price > 500 && product.price < 1000; // Example: products priced 50 and above
    if (priceFilter === "highh") return product.price > 1000 && product.price < 2000; // Example: products priced 50 and above

    return true;
  });

  // Brands to display (for example purposes)
  const allBrands = [
    "ALEXANDRE J", "Azzaro", "BARROIS", "BDK", "BOND No.9",
    "Bvlqari", "C.Hererra", "CHLOE", "CHRISTIAN DIOR", "CLIVE CHRISTIAN",
    "CREED", "DKNY", "DOLCE&GABBANA", "ELIE SAAB", "ESSENTIAL PARFUMS",
    "ESTEE LAUDER", "G.ARMANI", "GIVENCHY", "GUCCI", "GUERLAIN",

    "HERMES", "HUGO BOSS", "INITIO", "JO MALONE", "KILIAN",
    "LALIQUE", "Lancome", "MANCERA", "MARLY", "MEMO",
    "MONTALE", "Moschino", "N.Rodriguez", "ORLOV", "PRADA",
    "ROJA", "SHAIK", "SISLEY", "TIZIANA TERENZI", "TOM FORD",

    "TRUSSARDI", "V.SECRET", "VAN CLEEF", "Versace", "XERJOFF",
    "YSL", "Creed", "Tiziana Terenzi", "Giorgio Armani", "Parfums De Marly",
    "Chopard", "Amouage", "Molecule 04", "Molecule 05", "Xerjoff",
    "Tom Ford", "Mancera", "Bvlgari", "Eilish", "Karloff",

    "Kilian", "Initio", "Hibiscus MahaTá", "Attar", "Rituals of Anfar",
    "Loewe", "Hermes", "Gucci"
  ];

  // Filter brands based on search input
  const filteredBrands = allBrands.filter(brand =>
    brand.toLowerCase().includes(brandSearch.toLowerCase())
  );

  return (
    <>
      <div className='pb-[44px] bg-[black]'>
        <p className='text-[#DDB14C] text-[22px] md:text-4xl font-medium pt-8 md:pt-16 text-center'>Məhsullar</p>
        
        <div className='md:flex max-w-containerSm md:max-w-container mx-auto mt-8 justify-between'>
          {/* Left Filter Section */}
          <div className="md:w-[209px] hidden md:block ">
            <h4 className='text-white text-[20px] font-medium'>Filtrlər:</h4>

            <div className="mt-6">
  <label className="text-white font-medium mb-7">Kateqoriya:</label>
  <div className="flex flex-col mt-6 gap-3">
    {["men", "women", "unisex"].map((gender) => {
      const labelMap = {
        men: "Kişi ətirləri",
        women: "Qadın ətirləri",
        unisex: "Unisex ətirlər",
      };

      return (
        <div className="flex items-center" key={gender}>
          <input
            type="checkbox"
            id={gender}
            checked={!!genderFilters[gender]}
            onChange={() => handleGenderChange(gender)}
            className="accent-[#dfb66f] h-6 w-6" 
          />
          <label htmlFor={gender} className="ml-2 text-white">
            {labelMap[gender]}
          </label>
        </div>
      );
    })}
  </div>
</div>

            <div className="w-[211.01px] h-[0px] border border-[#3e3e3e] mt-4"></div>
            
            <div className="mt-4">
             <div className="flex justify-between">
             <label className='text-white font-medium'>Brendlər:</label>
              <button
                  onClick={clearBrandFilters}
                  className="text-[#5b5b5b] rounded"
                >
                  Təmizlə
                </button>
             </div>
              <div className="flex items-center mt-3">
                <input
                  type="text"
                  value={brandSearch}
                  onChange={(e) => setBrandSearch(e.target.value)}
                  placeholder="Axtar..."
                  className="mt-4 p-2 rounded-2xl w-[210px] bg-[#020202] border-[#595959] border-2 text-[white] mb-4"
                />
              </div>
              <div className="flex flex-col overflow-y-scroll mt-2 max-h-48 custom-scrollbar gap-3">
                {filteredBrands.map((brand) => ( // Show only the first 3 brands
                  <div className="flex items-center" key={brand}>
                    <input
                      type="checkbox"
                      id={brand}
                      checked={!!brandFilters[brand]}
                      onChange={() => handleBrandChange(brand)}
                      className="accent-[#dfb66f] h-6 w-6" 
                    />
                    <label htmlFor={brand} className="ml-2 text-white">{brand.charAt(0).toUpperCase() + brand.slice(1)}</label>
                  </div>
                ))}
              </div>
            </div>
            
            <div className="w-[211.01px] h-[0px] border border-[#3e3e3e] mt-4"></div>
            
            <div className="mt-4">
              <label className='text-white font-medium'>Qiymət:</label>
              <div className="flex flex-col mt-4">
               <div className="flex">
               <input
                  type="radio"
                  id="allPrices"
                  name="price"
                  value="default"
                  checked={priceFilter === "default"}
                  onChange={() => handlePriceChange("default")}
                />
                <label htmlFor="allPrices" className="text-white">Bütün qiymətlər</label>
               </div>

               <div className="flex">
               <input
                  type="radio"
                  id="under500"
                  name="price"
                  value="low"
                  checked={priceFilter === "low"}
                  onChange={() => handlePriceChange("low")}
                />
                <label htmlFor="under500" className=" text-white">100-500 azn</label>
               </div>

               <div className="flex">
               <input
                  type="radio"
                  id="under1000"
                  name="price"
                  value="high"
                  checked={priceFilter === "high"}
                  onChange={() => handlePriceChange("high")}
                />
                <label htmlFor="under1000" className=" text-white">500-1000 AZN</label>
               </div>

               <div className="flex">
               <input
                  type="radio"
                  id="under2000"
                  name="price"
                  value="highh"
                  checked={priceFilter === "highh"}
                  onChange={() => handlePriceChange("highh")}
                />
                <label htmlFor="under2000" className=" text-white">1000-2000 AZN</label>
               </div>

               
              </div>
            </div>

            
          </div>

          {/* Products Section */}
          <div className="md:w-[853px]">
            <div className="flex justify-between mb-4">
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange} 
              placeholder="Axtar..."
              className="mt-4 p-2 rounded-2xl w-[415px] bg-[#020202] border-[#595959] border-2 text-[white] hidden md:inline-block"
            />
            
            {/* Sort Options */}
            <div className="mt-4 hidden md:block">
              <label className='text-white'>Çeşidlə:</label>
              <select
                value={sortOption} 
                onChange={(e) => setSortOption(e.target.value)}
                className="mt-1 p-2 rounded-2xl bg-[#020202] text-[white] border-[#595959] border-2 ml-2"
              >
                <option value="default">Sonuncu</option>
                <option value="lowToHigh">Ən ucuz qiymətdən</option>
                <option value="highToLow">Ən baha qiymətdən</option>
              </select>
            </div>
            <div className="flex md:hidden justify-between mt-4 w-full gap-2">
            <div className=" w-1/2">
  <select
    value={sortOption}
    onChange={(e) => setSortOption(e.target.value)}
    className="p-2 rounded-2xl bg-[#020202] text-[white] border-[#595959] h-[40px] border-2 w-full pl-3 pr-6"  // Adjust padding here
  >
    <option value="default">Sonuncu</option>
    <option value="lowToHigh">Ən ucuz qiymətdən</option>
    <option value="highToLow">Ən baha qiymətdən</option>
  </select> 
</div>

<button
  onClick={toggleHamburgerMenu}
  className="flex items-center justify-center gap-2 rounded-2xl text-white border-[#595959] h-[40px] border-2 w-1/2"
>
  <span className="text-sm font-medium">Filterlə</span> 
  <img className="w-4 h-4" src={filterle} alt="filter icon" />
</button>

<button className="md:hidden focus:outline-none">  
  {isHamburgerMenuOpen && (  
    <div
      className={`fixed inset-y-0 bg-[black] left-0 transform ${
        isHamburgerMenuOpen ? "translate-x-0" : "-translate-x-full"
      } transition-transform duration-300 ease-in-out w-full z-50 shadow-lg`}
    >
      <div className="flex flex-col items-start  w-full">
      <div className="bg-[#3F3F3F] w-full">
      <div className="flex max-w-containerSm mx-auto w-full h-[38px] items-center">
      <img onClick={toggleHamburgerMenu} className='w-4 h-4' src={close}></img>
          <button  className="text-white text-[18px] font-medium text-left ml-4">
          Filterlə:
            </button>
      </div>
      </div>
        <div className="h-screen max-w-containerSm mx-auto w-full">
          <div>

            <div className="mt-4 text-left">
              <label className="text-white font-medium">
                Kateqoriya:
              </label>
              <div className="flex flex-col items-start mt-3 gap-2"> {/* `items-start` added */}
                {["men", "women", "unisex"].map((gender) => {
                  const labelMap = {
                    men: "Kişi ətirləri",
                    women: "Qadın ətirləri",
                    unisex: "Unisex ətirlər",
                  };

                  return (
                    <div key={gender} className="flex items-center"> {/* Added `text-left` */}
                      <input
                        type="checkbox"
                        id={gender}
                        checked={!!genderFilters[gender]}
                        onChange={() => handleGenderChange(gender)}
                        className="accent-[#dfb66f] h-6 w-6 " 
                      />
                      <label htmlFor={gender} className="ml-2 text-white text-sm">
                        {labelMap[gender]}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="w-full h-[0px] border border-[#3e3e3e] mt-4"></div>

            <div className="mt-4 text-left">
              <div className="flex justify-between">
                <label className="text-white font-medium">Brendlər:</label>
                <button
                  onClick={clearBrandFilters}
                  className="text-[#5b5b5b] rounded"
                >
                  Təmizlə
                </button>
              </div>
              <div className="flex items-center mt-1">
                <input
                  type="text"
                  value={brandSearch}
                  onChange={(e) => setBrandSearch(e.target.value)}
                  placeholder="Axtar..."
                  className="mt-4 px-2 rounded-xl w-full bg-[#020202] border-[#595959] border-2 text-[white] mb-4  h-[40px]"
                />
              </div>
              <div className="flex flex-col overflow-y-scroll mt-2 max-h-48 items-start gap-2"> {/* `items-start` added */}
                {filteredBrands.map((brand) => (
                  <div className="flex items-center" key={brand}>
                    <input
                      type="checkbox"
                      id={brand}
                      checked={!!brandFilters[brand]}
                      onChange={() => handleBrandChange(brand)}
                      className="accent-[#dfb66f] h-6 w-6 " 
                    />
                    <label
                      htmlFor={brand}
                      className="ml-2 text-white text-sm"
                    >
                      {brand.charAt(0).toUpperCase() + brand.slice(1)}
                    </label>
                  </div>
                ))}
              </div>
            </div>

            <div className="w-full h-[0px] border border-[#3e3e3e] mt-4"></div>

            <div className="mt-4 text-left">
              <label className="text-white">Qiymət:</label>
              <div className="flex flex-col items-start mt-3">
                {[
                  { id: "allPrices", value: "default", label: "Bütün qiymətlər" },
                  { id: "under500", value: "low", label: "100-500 azn" },
                  { id: "under1000", value: "high", label: "500-1000 AZN" },
                  { id: "under2000", value: "highh", label: "1000-2000 AZN" },
                ].map((option) => (
                  <div key={option.id} className="flex items-center">
                    <input
                      type="radio"
                      id={option.id}
                      name="price"
                      value={option.value}
                      checked={priceFilter === option.value}
                      onChange={() => handlePriceChange(option.value)}
                      
                    />
                    <label htmlFor={option.id} className="text-white ml-2 text-sm">
                      {option.label}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )}
</button>

            </div>
            </div> 
            {isLoading ?  <p className="text-[white]"> Loading...</p> :
              <PaginatedItems itemsPerPage={12} items={finalProducts} />  
            }
           
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;


















































