import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';


function PaginatedItems({ itemsPerPage, items }) {
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
    
  };

  

  return (
    <>
     <div className='grid grid-cols-2 md:grid-cols-3 gap-4'>
  {currentItems && currentItems.map((item, index) => (
       <a href={`product-detail/${item.id}`}>
   <div className="bg-[#3F3F3F] rounded-[20px] min-h-[199px] md:w-[271px] md:min-h-[280px] pt-[1px] mx-auto md:mx-0 flex flex-col justify-between" key={index}>
   <div className="m-3 text-[white] flex flex-col justify-between h-full">
     <div className="bg-[white] rounded-xl h-[99px] md:h-[167px] mb-3 flex justify-center items-center">
       <img className="w-[103px] h-[79px] md:w-[147px] mx-auto" src={`https://www.kcollection.az/kcollection/${item.image}`} alt="" />
     </div>
     <div className="flex flex-col">
       <h3 className="text-left text-[14px] md:text-[18px] font-medium min-h-[40px] md:min-h-[48px]">{item.name}</h3>
       <h3 className="text-left text-[14px] md:text-base text-[#E9E9E9]">{item.gender === 'men' ? 'Kişi' : item.gender === 'women' ? 'Qadın' : 'Unisex'} • <span>{item.volume} ml</span></h3>
       <h3 className="text-[#dfb66f] text-left text-[16px] md:text-[20px] font-medium md:font-semibold">{item.price} AZN</h3>
     </div> 
   </div>
 </div>
 </a>
  ))}
</div>


<ReactPaginate
  nextLabel="sonrakı"
  onPageChange={handlePageClick}
  pageRangeDisplayed={3}
  pageCount={pageCount}
  previousLabel="əvvəlki"
  renderOnZeroPageCount={null}
  containerClassName="flex flex-wrap justify-center items-center space-x-1 my-4 overflow-x-auto"
  pageClassName="inline-block px-2 py-1 text-sm md:text-base rounded bg-gray-200 hover:bg-gray-300"
  previousClassName="inline-block px-2 py-1 text-sm md:text-base rounded bg-gray-200 hover:bg-gray-300"
  nextClassName="inline-block px-2 py-1 text-sm md:text-base rounded bg-gray-200 hover:bg-gray-300"
  activeClassName="bg-blue-600 text-white"
  disabledClassName="text-gray-400 cursor-not-allowed"
/> 


    </>
  );
}

export default PaginatedItems;
