import React, { useEffect, useState } from 'react';
import { close, logo, magnifier } from '../../assets/images';
import axios from 'axios';
import PaginatedItems from './PaginatedItems';
import PaginatedItemsMobile from './PaginatedItemsMobile';
import { useLocation } from 'react-router-dom';

const Header = () => {
  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isSearchVisible, setIsSearchVisible] = useState(false);

  const toggleSearch = () => {
    setIsSearchVisible(!isSearchVisible);
    setSearchQuery('');
  };

  const toggleSearch2 = () => {
    setIsSearchVisible(!isSearchVisible);
    setSearchQuery("")
  };


  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await axios.get(
        `https://www.kcollection.az/kcollection/dashboard.php`,
        { withCredentials: false }
      );
      setProducts(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

    const location = useLocation(); // Get current route

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const isActiveLink = (path) => location.pathname === path;

  const filteredProducts = searchQuery
    ? products.filter((product) =>
        product.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];

      const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
      const toggleHamburgerMenu = () => {
        setIsHamburgerMenuOpen(!isHamburgerMenuOpen);
        setSearchQuery('');
      };
      

  return (
    <>
      <div className="max-w-containerSm md:max-w-container mx-auto h-[86px] py-[10px] flex justify-between items-center relative">

        <div className='flex items-center md:hidden'>       <a href='/'> <img className='w-[50px] h-[50px]' src={logo}></img></a></div>
                <button className="md:hidden focus:outline-none" onClick={toggleHamburgerMenu}>
                <i className="fa-solid fa-bars text-[24px] text-[white]"></i>
               
              </button>
        {/* Logo ve Menü sadece search kapalıyken görünecek */}
        {!isSearchVisible && (
          <>
            <div className="hidden md:block">
              <a className="hidden md:block" href="/">
                <img alt="logo" height={76} width={76} src={logo} />
              </a>
            </div>

            <div className="md:flex items-center w-[500px] hidden">
              <nav>
                <ul className="flex text-lg justify-between text-white">
                  <a className="mr-[50px]" href="/">
                    <li>Ana səhifə</li>
                  </a>
                  <a className="mr-[50px]" href="/products">
                    <li>Məhsullar</li>
                  </a>
                  <a className="mr-[50px]" href="/about">
                    <li>Haqqımızda</li>
                  </a>
                  <a href="/contact">
                    <li>Əlaqə</li>
                  </a>
                </ul>
              </nav>
            </div>
          </>
        )}

        {/* Search Input - Ortalanmış */}
        {isSearchVisible ? (
          <div className="absolute w-full justify-center hidden md:flex">
            <div className="relative">
            <button className="absolute top-3 left-4 text-white">
                <img className='w-5 h-5' src={magnifier}></img>
              </button>
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder="Axtar..."
                className="rounded-[50px] w-[300px] md:w-[755px] bg-[#020202] h-[44px] border-[#C3C3C3] border-2 px-12 text-white"
              />
              <button onClick={toggleSearch} className="absolute top-3 right-4 text-white">
                <img className='w-4 h-4' src={close}></img>
              </button>
            </div>
          </div>
        ) : (
          <button onClick={toggleSearch} className="text-xl text-white hidden md:block">
            <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" stroke="#C3C3C3" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.5837 14.2917C17.5566 14.2917 14.292 17.5562 14.292 21.5833C14.292 25.6104 17.5566 28.875 21.5837 28.875C25.6107 28.875 28.8753 25.6104 28.8753 21.5833C28.8753 17.5562 25.6107 14.2917 21.5837 14.2917ZM13.042 21.5833C13.042 16.8659 16.8662 13.0417 21.5837 13.0417C26.3011 13.0417 30.1253 16.8659 30.1253 21.5833C30.1253 23.7171 29.3429 25.6681 28.0494 27.1652L30.7756 29.8914C31.0197 30.1355 31.0197 30.5312 30.7756 30.7753C30.5315 31.0193 30.1358 31.0193 29.8917 30.7753L27.1655 28.049C25.6684 29.3426 23.7174 30.125 21.5837 30.125C16.8662 30.125 13.042 26.3008 13.042 21.5833Z"
                fill="white"
              />
            </svg>
          </button>
        )}
      </div>

      {/* Arama Sonuçları */}
      {isSearchVisible && searchQuery && (
        <div className="absolute top-0 left-0 w-full bg-black z-20 mt-[86px] hidden md:block">
          {isLoading ? (
            <p className="text-black text-center">Loading...</p>
          ) : (
            <PaginatedItems itemsPerPage={12} items={filteredProducts} />
          )}
        </div>
      )}
      {searchQuery && ( 
        <div className="absolute top-0 left-0 w-full bg-black mt-[140px] md:hidden z-50">
          {isLoading ? (
            <p className="text-black text-center">Loading...</p>
          ) : (
            <PaginatedItemsMobile itemsPerPage={12} items={filteredProducts} />
          )}
        </div>
      )}

      
      {isHamburgerMenuOpen && (
        <div className={`fixed inset-y-0 bg-[black] left-0 transform ${isHamburgerMenuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out w-full z-40 shadow-lg`}>
                 <div className="flex flex-col items-start max-w-containerSm mx-auto mt-4 w-full ">
                 <div className="flex justify-between w-full">
                 <a className='block' href='/'> <img src={logo} alt="Logo" className="w-[50px]" /></a>

<button onClick={toggleHamburgerMenu}>
<img className='w-4 h-4 ' src={close}></img>
</button>
 
       </div>
  <div className="items-center justify-center h-screen w-full mt-4">
    <div className="gap-4 max-w-containerSm flex flex-col">
      <div className="relative mt-3">
            <button className="absolute top-3 left-4 text-white">
                <img className='w-5 h-5' src={magnifier}></img>
              </button>
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder="Axtar..."
                className="rounded-[50px] w-full md:w-[755px] bg-[#020202] h-[44px] border-[#C3C3C3] border-2 px-12 text-white"
              />
              {/* <button onClick={toggleSearch} className="absolute top-3 right-20 text-white">
                <img className='w-4 h-4' src={close}></img>
              </button> */}
            </div>
      <a className={`text-[18px] text-left font-normal mt-4 ${isActiveLink('/') ? 'text-[#dfb66f]' : 'text-[white]'}`} href="/"> Ana səhifə</a>
      <a href="/products" className={`text-[18px] text-left font-normal ${isActiveLink('/products') ? 'text-[#dfb66f]' : 'text-[white]'}`}>Məhsullar</a>
      <a href="/about" className={`text-[18px] text-left font-normal ${isActiveLink('/about') ? 'text-[#dfb66f]' : 'text-[white]'}`}>Haqqımızda</a>
      <a href="/contact" className={`text-[18px] text-left font-normal ${isActiveLink('/contact') ? 'text-[#dfb66f]' : 'text-[white]'}`}>Əlaqə</a>
    </div>
  </div>
</div>

        </div>
      )} 
    </>
  );
};

export default Header;

